$(document).ready(function(){

  // $('.m-menu').click(function(){
  //   $('html, body').toggleClass('noscroll');
  //   $('body').toggleClass('m-menu-open');
  // });

  function openListView(index){
    if($('body').hasClass('gridview')){
      $('body').removeClass('gridview').addClass('listview');
      // add slick
      $('.grid').slick();
      document.getElementsByClassName('slick-active')[0].focus()
      resizeImg();
      if(index != -1) {
        $('.grid').slick("goTo", index, true);
      }
    }
  }

  function closeListView(){
    $('body').removeClass('listview').addClass('gridview');
    //   // remove slick
    if($('.grid').hasClass('slick-initialized')){
      $('.grid').slick('unslick');
    }
  }

  $('.grid-view-toggle').click(function(){
    closeListView();
  });

  // go from thumb to slick view
  $('.gridview .item').on("click", function(e){
    e.stopPropagation();
    var idx = $(this).data("workindex");
    openListView(idx);
  });



  function resizeImg() {
    var wHeight = $( window ).height();
  //   aspectRatio = ( oldWidth / oldHeight )
    var maxHeight = wHeight - 220;

    $slickSlider = $('.grid');
    $slickSlider.find('.slick-slide').height('auto');

    $slickSlider.find('.slick-slide img').css('width', 'auto');
    $slickSlider.find('.slick-slide img').css('max-height', maxHeight + 'px');

  }

  // $(window).on('load', function(){
  //   console.log('load');
  //   //resizeImg();
  // })
  $(window).on('resize', function(){
    resizeImg();
  })


  // scrolling stuff

  var scrolled = 0;
  function handleScroll () {
    scrolled = window.scrollY;
    var scrollOffsetFirst = 100;
    var scrollOffset = 200;
    if(scrolled > scrollOffsetFirst){
      document.querySelector('body').classList.add('prescrolling');
    }else{
      document.querySelector('body').classList.remove('prescrolling');
    }
    if(scrolled > scrollOffset){
      document.querySelector('body').classList.add('scrolling');
    }else{
      document.querySelector('body').classList.remove('scrolling');
    }
  }

  window.addEventListener('scroll', handleScroll);

});